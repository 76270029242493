const breakpoints = {
  // Taken directly from Tailwind Docs
  values: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536,
  }
};

breakpoints['values']['paragraph'] = 600;

export default breakpoints;
