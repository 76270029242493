export type ButtonType = {
  name: string;
  sections?: { label: string; path: string }[];
  state?: 'beta' | 'soon' | 'new' | 'disabled';
  path: string | null;
  variation: ButtonActionType;
  props?: object;
  getAbsolutelyPositionComponent?: () => React.ReactNode;
  comingSoon?: boolean;
  titleOnly?: boolean;
};

export const buttonStateChipColorMap = {
  soon: 'amber',
  beta: 'primary',
  new: 'primary'
};

export type ButtonActionType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'mini'
  | 'funky'
  | 'alt-funky';

export type ButtonColorType =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | string;
